import React, { useState } from 'react'

const FAQ = () => {
  const [isContentVisible, setIsContentVisible] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  })

  const toggleContent = (index) => {
    setIsContentVisible((prevVisibility) => ({
      ...prevVisibility,
      [index]: !prevVisibility[index],
    }))
  }

  const faqData = [
    {
      question:
        'Do you provide the screening content for the private screenings?',
      answer:
        'Unfortunately we do not provide the screening content due to copyright issues. Customers are required to bring their own OTT logins..',
    },
    {
      question:
        'Is alcohol served at Nimma cinema, and can guests smoke on the premises?',
      answer:
        'We do not serve alcohol, and smoking is prohibited within our premises.',
    },
    {
      question: 'Are there age restrictions for booking at Nimma Cinema?',
      answer: 'Persons under the age of 18 must book accompanied by an adult.',
    },
    {
      question: 'Can I bring my pet with me?',
      answer: 'Pets are not allowed on our premises.',
    },
    {
      question: 'Can I bring my own decorations for special occasions?',
      answer:
        'No, we provide the decor. No external decor items are allowed, specifically poppers, snow sprays, etc.',
    },
    {
      question: 'Are there any charges for bringing outside food?',
      answer:
        'Standard cleaning charges of ₹100 will be applied if outside food is consumed. In case of excessive mess, ₹500 will be levied.',
    },
  ]

  return (
    <div className="faq-container" id="FAQ">
      <div className="heading">FAQ</div>
      <div className="qes-cont">
        {faqData.map((entry, index) => (
          <div key={index} className="data-cont">
            <button
              className="button-1"
              onClick={() => toggleContent(index + 1)}
            >
              {index + 1}. {entry.question}
              {isContentVisible[index + 1] && (
                <div className="faq-content">
                  <p>{entry.answer}</p>
                </div>
              )}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FAQ
