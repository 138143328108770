import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import clsx from 'clsx'
import { Spiral as Hamburger } from 'hamburger-react'

export default function Header(props) {
  const { scrolledPosition } = props
  const [isNavVisible, setNavVisibility] = useState(false)
  const [isAcive, setIsActiveTab] = useState('')

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 700px)')
    mediaQuery.addListener(handleMediaQueryChange)
    handleMediaQueryChange(mediaQuery)

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange)
    }
  }, [])

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      // setIsSmallScreen(true)
    } else {
      // setIsSmallScreen(false)
    }
  }

  const whatsapphandel = () => {
    var phoneNumber = '6361534344'
    var whatsappURL = 'https://wa.me/' + phoneNumber
    window.open(whatsappURL, '_blank')
  }

  return (
    <header
      className={clsx(
        'Header',
        scrolledPosition > 300 && !isNavVisible
          ? 'Header-scrolled'
          : isNavVisible
          ? 'navOpened'
          : '',
      )}
    >
      <div className="Logo">
        {scrolledPosition > 300 && (
          <a href="#main" onClick={() => setNavVisibility(false)}>
            <img
              src={require('../assets/images/logo_trans.png')}
              alt="logo"
              className="img1"
            />
          </a>
        )}
      </div>

      <div className="msg-button">
        <button onClick={whatsapphandel} className="beautiful-button">
          Book Now
        </button>
        <CSSTransition
          in={isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
          transitionName="example"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          <>
            {isNavVisible && (
              <nav className="Nav">
                <div className="nav-items-cont">
                  <a
                    href="#about-us"
                    className={clsx(isAcive === 'about-us' ? 'active' : '')}
                    onClick={() => {
                      setIsActiveTab('about-us')
                      setNavVisibility(false)
                    }}
                  >
                    About US
                  </a>
                  <a
                    href="#what_you_can_do"
                    className={clsx(
                      isAcive === 'what_you_can_do' ? 'active' : '',
                    )}
                    onClick={() => {
                      setIsActiveTab('what_you_can_do')
                      setNavVisibility(false)
                    }}
                  >
                    What You Can Do
                  </a>
                  <a
                    href="#what_we_offer"
                    className={clsx(
                      isAcive === 'what_we_offer' ? 'active' : '',
                    )}
                    onClick={() => {
                      setIsActiveTab('what_we_offer')
                      setNavVisibility(false)
                    }}
                  >
                    What We Offer
                  </a>
                  <a
                    href="#gallery"
                    className={clsx(isAcive === 'gallery' ? 'active' : '')}
                    onClick={() => {
                      setIsActiveTab('gallery')
                      setNavVisibility(false)
                    }}
                  >
                    Gallery
                  </a>

                  <a
                    href="#addonservices"
                    className={clsx(
                      isAcive === 'addonservices' ? 'active' : '',
                    )}
                    onClick={() => {
                      setIsActiveTab('addonservices')
                      setNavVisibility(false)
                    }}
                  >
                    Add On Services
                  </a>

                  <a
                    href="#FAQ"
                    className={clsx(isAcive === 'FAQ' ? 'active' : '')}
                    onClick={() => {
                      setIsActiveTab('FAQ')
                      setNavVisibility(false)
                    }}
                  >
                    FAQ
                  </a>
                </div>
              </nav>
            )}
          </>
        </CSSTransition>

        <Hamburger
          color="var(--text-white)"
          onToggle={() => setNavVisibility(!isNavVisible)}
          distance="sm"
          size={50}
          rounded={false}
          barHeight={10}
          className="hamburger"
          toggled={isNavVisible}
        />
      </div>
    </header>
  )
}
