import React, { useState } from 'react'
import Header from './components/header'
import Banner from './views/banner'
import AboutUs from './views/aboutus'
import OurServices from './views/OurServices'
import Gallery from './views/gallery'
import FAQ from './views/FAQ'
import Footer from './components/footer'
import Additionalinfo from './views/Additional-info'
import './styles/main.scss'
import AddOnServices from './views/addOnServices'

const App = () => {
  const myRef = React.createRef()
  const [scroll, setScroll] = useState(0)

  const onScroll = () => {
    const scrollTop = myRef.current.scrollTop
    setScroll(scrollTop)
  }

  return (
    <div className="app-routes" onScroll={onScroll} ref={myRef}>
      <Header scrolledPosition={scroll} />
      <Banner />
      <AboutUs />
      <OurServices />
      <Additionalinfo />
      <Gallery />
      <AddOnServices />
      <FAQ />
      <Footer />
    </div>
  )
}

export default App
