import React from 'react'
import about1 from '../assets/images/about3.jpeg'
import about2 from '../assets/images/about1.jpeg'
import about3 from '../assets/images/about4.jpeg'
import about4 from '../assets/images/about2.jpeg'
import about5 from '../assets/images/galler4.jpeg'
import about6 from '../assets/images/galler5.jpeg'
import about7 from '../assets/images/about5.jpeg'
import about8 from '../assets/images/about6.jpeg'

const Gallery = () => {
  return (
    <div className="gallery-cont" id="gallery">
      <div className="heading">Gallery</div>
      <div className="img-cont">
        <img className="img7" src={about7} alt="error" />
        <img className="img1" src={about1} alt="error" />
        <img className="img2" src={about2} alt="error" />
        <img className="img3" src={about3} alt="error" />
        <img className="img4" src={about4} alt="error" />
        <img className="img5" src={about5} alt="error" />
        <img className="img6" src={about6} alt="error" />
        <img className="img8" src={about8} alt="error" />
      </div>
    </div>
  )
}

export default Gallery
