import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import imag1 from '../assets/images/caro1.jpeg'
import imag2 from '../assets/images/caro2.jpeg'
import imag3 from '../assets/images/caro3.jpeg'
import imag4 from '../assets/images/caro4.jpg'
import imag5 from '../assets/images/caro5.jpeg'

const Banner = () => {
  return (
    <div className="banner-cont" id="main">
      <div className="conten-overlay">
        <div className="content-left">
          <img src={require('../assets/images/logo_trans.png')} alt="" />
          <span>Your Home Theater</span>
        </div>

        <div className="content-right">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval={2000}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
          >
            <div>
              <img alt="" src={imag1} />
            </div>
            <div>
              <img alt="" src={imag2} />
            </div>
            <div>
              <img alt="" src={imag3} />
            </div>
            <div>
              <img alt="" src={imag4} />
            </div>
            <div>
              <img alt="" src={imag5} />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default Banner
