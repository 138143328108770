import React from 'react'
import { BsProjector } from 'react-icons/bs'
import { GiTheater } from 'react-icons/gi'
import { TbBrandDolbyDigital } from 'react-icons/tb'
import { IoGiftSharp } from 'react-icons/io5'
import { CgScreenShot } from 'react-icons/cg'

const Additionalinfo = () => {
  return (
    <div className="addtional-info" id="what_we_offer">
      <div className="heading">What We Offer</div>
      <div className="info-cards">
        <div className="info-card">
          <div className="icon">
            <BsProjector />
          </div>
          <div className="info-title">4K Projector</div>
        </div>
        <div className="info-card">
          <div className="icon">
            <GiTheater />
          </div>
          <div className="info-title">165 inch screen</div>
        </div>
        <div className="info-card">
          <div className="icon">
            <TbBrandDolbyDigital />
          </div>
          <div className="info-title">Dolby Atmos</div>
        </div>
        <div className="info-card">
          <div className="icon">
            <IoGiftSharp />
          </div>
          <div className="info-title">Customizable Packages</div>
        </div>
        <div className="info-card">
          <div className="icon">
            <CgScreenShot />
          </div>
          <div className="info-title">Your OTT Content</div>
        </div>
      </div>
    </div>
  )
}

export default Additionalinfo
