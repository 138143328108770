import React, { useState } from 'react'
import { FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa'
import { Fab, Action } from 'react-tiny-fab'
import 'react-tiny-fab/dist/styles.css'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Modal } from 'react-bootstrap'
import { IoMdClose } from 'react-icons/io'
import { BsHeadset } from 'react-icons/bs'
import { AiOutlineMail } from 'react-icons/ai'
import { BsTelephoneFill } from 'react-icons/bs'

const Footer = () => {
  const [show, setShow] = useState(false)

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const [showRefundPolicy, setShowRefundPolicy] = useState(false)

  const handleShowRefund = () => setShowRefundPolicy(true)
  const handleCloseRefund = () => setShowRefundPolicy(false)

  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)

  const handleShowPolicy = () => setShowPrivacyPolicy(true)
  const handleClosePolicy = () => setShowPrivacyPolicy(false)

  function handleClickPhone() {
    document.location.href = 'tel:6361534344'
  }

  function handleClickEmail() {
    window.location.href =
      'mailto:nimmacinemadvg@gmail.com?subject=Subject&body=message%20goes%20here'
  }

  return (
    <div className="footer-cont" id="contact-us">
      <div className="links-cont">
        <a href="#FAQ" className="link">
          FAQ
        </a>
        <span className="link" onClick={handleShow}>
          Terms & Conditions
        </span>
        <span className="link" onClick={handleShowRefund}>
          Refund Policy
        </span>
        <span className="link" onClick={handleShowPolicy}>
          Privacy Policy
        </span>
      </div>
      <div className="icons">
        <FaFacebookSquare
          onClick={() =>
            window.open('https://www.facebook.com/nimmacinemadvg', '_blank')
          }
        />
        <FaInstagramSquare
          onClick={() =>
            window.open('https://www.instagram.com/nimmacinema_dvg/', '_blank')
          }
        />
      </div>

      <div className="h2">
        Copyright @ {new Date().getFullYear()}, Nimma Cinema <br /> All Rights
        Reserved Designed and Developed By{' '}
        <a href="https://hextrem.com/" target="_blank" rel="noreferrer">
          Hextrem Technologies
        </a>
      </div>

      <Fab icon={<BsHeadset />} alwaysShowTitle={true}>
        <Action text="Email" onClick={() => handleClickEmail()}>
          <AiOutlineMail></AiOutlineMail>
        </Action>
        <Action text="Phone" onClick={() => handleClickPhone()}>
          <BsTelephoneFill color="#fff"></BsTelephoneFill>
        </Action>
      </Fab>

      <FloatingWhatsApp
        phoneNumber="6361534344"
        accountName="Nimma Cinema"
        avatar={require('../assets/images/logo.png')}
      />

      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="elegant-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="modal-title">
              Terms & Conditions
            </Modal.Title>
            <IoMdClose
              onClick={handleClose}
              style={{ fontSize: '2rem', cursor: 'pointer' }}
            />
          </Modal.Header>
          <Modal.Body>
            <ol>
              <li>
                Celebrations without decorations are not allowed. Decorations
                are required for all celebrations to create a festive
                atmosphere.
              </li>
              <li>
                Ensure no damage to any decorations, balloons, lighting, etc.
                Customers must avoid causing any damage to provided decorations
                and items like balloons and lighting.
              </li>
              <li>
                Customers should vacate the theatre with all their belongings on
                or before the end time. Customers must leave the theatre
                premises along with their belongings before the scheduled end
                time of their booking.
              </li>
              <li>
                Guests are requested to maintain CLEANLINESS inside the theatre;
                in case of a mess, cleaning charges will be applicable.
                Customers are expected to keep the theatre clean during their
                stay, and cleaning charges may apply if there is a mess.
              </li>
              <li>
                Party poppers/Cold Fire/Snow sprays or any similar kind of items
                are strictly prohibited inside the theatre. For safety reasons,
                items like party poppers, cold fire, snow sprays, or similar
                items are not allowed inside the theatre.
              </li>
              <li>
                Illegal activities such as smoking and drinking alcohol are
                strictly prohibited. Smoking and drinking alcohol are strictly
                forbidden within the theatre premises due to legal restrictions.
              </li>
              <li>
                We are not responsible for your personal belongings. Customers
                are responsible for the safety of their personal belongings, and
                Nimma Cinema will not be liable for any loss or theft.
              </li>
              <li>
                Customers can’t move any properties or goods that are organized
                under the circumstances. Customers are not allowed to move or
                rearrange any properties or goods set up by Nimma Cinema for the
                event.
              </li>
              <li>
                Content Policy: We provide space, infrastructure and equipment
                required for streaming of the content. We do not own or
                distribute content in any form.
              </li>
              <li>
                The customer should carry a valid proof of ownership for
                streaming the content and cannot stream any content prohibited
                in India. Customers must provide proof of ownership for content
                streaming and comply with India’s content regulations. Users
                cannot stream any content prohibited in India.
              </li>
              <li>
                Couples below 18 years of age are not allowed and no refund will
                be issued. Nimma Cinema does not allow couples below 18 years of
                age to use their facilities. Users accompanying minors\children
                should stream content in compliance with age restriction
                guidelines.
              </li>
              <li>
                Refund policy – Amount would be refunded in case of cancellation
                of booking at least 72 hours of booking time, and no refund for
                Friday, Saturday, Sunday booking slots. Customers can receive a
                refund of 50% for cancellations made 72 hours before the booking
                time, but no refund is provided for Friday, Saturday, and Sunday
                bookings.
              </li>
              <li>
                In case of any public holidays or festivals, ensure cancellation
                of booking is not acceptable. Bookings made during public
                holidays or festivals cannot be canceled.
              </li>
              <li>
                Illegal content is prohibited; we will not entertain such
                activities and don’t allow such customers. Nimma Cinema strictly
                prohibits the use or dissemination of illegal content and
                reserves the right to deny entry to customers engaged in such
                activities.
              </li>
              <li>
                Technical Issues: In the event of technical issues during the
                booking or while using the facilities, Nimma Cinema will make
                reasonable efforts to resolve them promptly. However, no
                liability will be assumed for any disruptions caused by
                technical problems beyond our control.
              </li>
              <li>
                Parking at your own risk: Nimma Cinema 1 does not take
                responsibility for any damage, theft, or loss of vehicles parked
                on the premises. Customers are advised to park their vehicles at
                their own risk.
              </li>
              <li>
                Wi-fi facilities will be provided. Nimma Cinema offers Wi-fi
                facilities for customers’ convenience.
              </li>
              <li>
                Customers are not allowed to touch or change any technical or
                non-technical items in the theatre, such as screen projectors,
                speakers, or lights. In case of any damage, the appropriate fine
                will be raised upon it. Customers must not touch or alter any
                technical or non-technical equipment within the theatre, and any
                damages will result in fines.
              </li>
              <li>
                Customers are not allowed to carry out any property or
                decoration items in the theatre without our permission.
                Customers must seek permission before bringing in any external
                property or decoration items.
              </li>
              <li>
                Content is not provided from our end, but you need to bring your
                own account to log in, and the required facilities will be given
                from our end. Nimma Cinema does not provide content for
                streaming; customers must use their own accounts, and necessary
                facilities will be provided.
              </li>
              <li>
                Outside food is allowed. Customers are allowed to bring outside
                food into the theatre.
              </li>
              <li>
                Nimma Cinema completely deserves the right to cancel the booking
                on any unforeseen instances, and the booking amount will be
                refunded in 8 working days. Nimma Cinema reserves the right to
                cancel bookings due to unforeseen circumstances, and customers
                will receive a refund within 8 working days.
              </li>
              <li>
                Ensuring a Safe and Respectful Environment: Our Commitment to
                Addressing Misconduct “Our staff members have the legitimate
                right to raise questions and concerns regarding any cases of
                misconduct. In the event that any form of inappropriate
                behaviour is observed, we are committed to taking decisive and
                rigorous actions against the involved customer. This approach
                ensures a respectful and safe environment for both our staff and
                customers, fostering a positive and harmonious atmosphere.”
              </li>
              <li>
                Charges Apply for Ages 3 and Above: We would like to clarify
                that a fee will be applicable for individuals who fall within
                the age group of three years and above. This policy ensures that
                those who are three years old or older will be subject to
                charges for the services or facilities provided. We appreciate
                your understanding and cooperation regarding this policy.
              </li>
              <li>
                Appropriate fines will be raised in case of any damages happened
                in the premises.
              </li>
              <li>
                Buffer time cannot be used without permission. If buffer time is
                taken every 10 minutes, additional hourly charges will be
                applied. The booking time includes setup time for streaming any
                content. Users should vacate the theater on time without fail.
                It is not permissible to utilize buffer time without obtaining
                prior permission. In the event that buffer time is utilized at
                intervals of every 10 minutes, there will be a penalty in the
                form of extra charges equivalent to an additional hour’s cost.
                This policy is in place to ensure efficient use of resources and
                to discourage frequent or unnecessary consumption of buffer
                time, which could potentially disrupt schedules and services.
              </li>
            </ol>
          </Modal.Body>
        </Modal>
      )}

      {showRefundPolicy && (
        <Modal
          show={showRefundPolicy}
          onHide={handleCloseRefund}
          centered
          className="elegant-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="modal-title">Refund Policy</Modal.Title>
            <IoMdClose
              onClick={handleCloseRefund}
              style={{ fontSize: '2rem', cursor: 'pointer' }}
            />
          </Modal.Header>
          <Modal.Body>
            <ol>
              <li>
                Please note that cancellation or rescheduling is not allowed for
                Friday, Saturday, and Sunday slots.
              </li>
              <li>
                For the remaining days, 50% of the booking amount (excluding
                taxes if applicable) would be refunded on cancellations done
                before 72 hours from the commencement time of booking.
              </li>
              <li>
                We allow rescheduling only before 72 hours from the commencement
                time of booking.
              </li>
              <li>There will be no refunds in case of rescheduled bookings.</li>
              <li>Refunds might take 7-15 business days.</li>
              <li>Bookings are not transferable.</li>
              <li>
                Cancellation or Rescheduling policy might change for Festivals,
                National holidays, etc. Please confirm with the team before
                making the booking.
              </li>
              <li>
                No cancellation is possible for experiences booked for 1st
                January, 14th February, and 25th December as these are special
                packages.
              </li>
            </ol>
          </Modal.Body>
        </Modal>
      )}

      {showPrivacyPolicy && (
        <Modal
          show={showPrivacyPolicy}
          onHide={handleClosePolicy}
          centered
          className="elegant-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="modal-title">Privacy Policy</Modal.Title>
            <IoMdClose
              onClick={handleClosePolicy}
              style={{ fontSize: '2rem', cursor: 'pointer' }}
            />
          </Modal.Header>
          <Modal.Body>
            <section>
              <p>
                This Privacy Policy describes Our policies and procedures on the
                collection, use and disclosure of Your information when You use
                the Service and tells You about Your privacy rights and how the
                law protects You.
              </p>

              <p>
                We use Your Personal data to provide and improve the Service. By
                using the Service, You agree to the collection and use of
                information in accordance with this Privacy Policy.
              </p>
            </section>

            <section>
              <h2>Interpretation and Definitions</h2>

              <h3>Interpretation</h3>

              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>

              <h3>Definitions</h3>

              <dl>
                <dt>Account</dt>
                <dd>
                  A unique account created for You to access our Service or
                  parts of our Service.
                </dd>

                <dt>Company</dt>
                <dd>
                  Referred to as either "the Company," "We," "Us," or "Our" in
                  this Agreement, refers to Nimma Cinema, 3rd Floor,
                  3371/76/1-3, Shamanur Road, Extended MCC B Block, Davanagere –
                  577004, Karnataka, India.
                </dd>

                <dt>Cookies</dt>
                <dd>
                  Small files that are placed on Your computer, mobile device or
                  any other device by a website, containing the details of Your
                  browsing history on that website among its many uses.
                </dd>

                <dt>Country</dt>
                <dd>Karnataka, India</dd>

                <dt>Device</dt>
                <dd>
                  Any device that can access the Service such as a computer, a
                  cell phone or a digital tablet.
                </dd>

                <dt>Personal Data</dt>
                <dd>
                  Any information that relates to an identified or identifiable
                  individual.
                </dd>

                <dt>Service</dt>
                <dd>
                  The Website, referring to Nimma Cinema, accessible from
                  http://nimmacinema.com
                </dd>

                <dt>Service Provider</dt>
                <dd>
                  Any natural or legal person who processes the data on behalf
                  of the Company. It refers to third-party companies or
                  individuals employed by the Company to facilitate the Service,
                  to provide the Service on behalf of the Company, to perform
                  services related to the Service or to assist the Company in
                  analyzing how the Service is used.
                </dd>

                <dt>Third-party Social Media Service</dt>
                <dd>
                  Any website or social network website through which a User can
                  log in or create an account to use the Service.
                </dd>

                <dt>Usage Data</dt>
                <dd>
                  Data collected automatically, either generated by the use of
                  the Service or from the Service infrastructure itself (e.g.,
                  the duration of a page visit).
                </dd>

                <dt>Website</dt>
                <dd>Nimma Cinema, accessible from http://nimmacinema.com</dd>

                <dt>You</dt>
                <dd>
                  The individual accessing or using the Service, or the company,
                  or other legal entity on behalf of which such individual is
                  accessing or using the Service, as applicable.
                </dd>
              </dl>
            </section>

            <section>
              <h2>Collecting and Using Your Personal Data</h2>

              <h3>Types of Data Collected</h3>

              <h4>Personal Data</h4>

              <p>
                While using Our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may
                include, but is not limited to:
              </p>

              <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
              </ul>

              <h4>Usage Data</h4>

              <p>
                Usage Data is collected automatically when using the Service.
              </p>

              <p>
                Usage Data may include information such as Your Device's
                Internet Protocol address (e.g., IP
              </p>
            </section>

            <section>
              <h2>Information from Third-Party Social Media Services</h2>

              <p>
                The Company allows You to create an account and log in to use
                the Service through the following Third-party Social Media
                Services:
              </p>

              <ul>
                <li>Google</li>
                <li>Facebook</li>
                <li>Twitter</li>
                <li>LinkedIn</li>
              </ul>

              <p>
                If You decide to register through or otherwise grant us access
                to a Third-Party Social Media Service, We may collect Personal
                data that is already associated with Your Third-Party Social
                Media Service's account, such as Your name, Your email address,
                Your activities or Your contact list associated with that
                account.
              </p>

              <p>
                You may also have the option of sharing additional information
                with the Company through Your Third-Party Social Media Service's
                account. If You choose to provide such information and Personal
                Data, during registration or otherwise, You are giving the
                Company permission to use, share, and store it in a manner
                consistent with this Privacy Policy.
              </p>
            </section>

            <section>
              <h2>Tracking Technologies and Cookies</h2>

              <p>
                We use Cookies and similar tracking technologies to track the
                activity on Our Service and store certain information. Tracking
                technologies used are beacons, tags, and scripts to collect and
                track information and to improve and analyze Our Service. The
                technologies We use may include:
              </p>

              <ul>
                <li>
                  Cookies or Browser Cookies. A cookie is a small file placed on
                  Your Device. You can instruct Your browser to refuse all
                  Cookies or to indicate when a Cookie is being sent. However,
                  if You do not accept Cookies, You may not be able to use some
                  parts of our Service. Unless You have adjusted Your browser
                  setting so that it will refuse Cookies, our Service may use
                  Cookies.
                </li>
                <li>
                  Flash Cookies. Certain features of our Service may use local
                  stored objects (or Flash Cookies) to collect and store
                  information about Your preferences or Your activity on our
                  Service. Flash Cookies are not managed by the same browser
                  settings as those used for Browser Cookies. For more
                  information on how You can delete Flash Cookies, please read
                  "Where can I change the settings for disabling, or deleting
                  local shared objects?" available at
                  https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                </li>
                <li>
                  Web Beacons. Certain sections of our Service and our emails
                  may contain small electronic files known as web beacons (also
                  referred to as clear gifs, pixel tags, and single-pixel gifs)
                  that permit the Company, for example, to count users who have
                  visited those pages or opened an email and for other related
                  website statistics (for example, recording the popularity of a
                  certain section and verifying system and server integrity).
                </li>
              </ul>

              <p>
                Cookies can be "Persistent" or "Session" Cookies. Persistent
                Cookies remain on Your personal computer or mobile device when
                You go offline, while Session Cookies are deleted as soon as You
                close Your web browser. Learn more about cookies on the Privacy
                Policies website article.
              </p>

              <p>
                We use both Session and Persistent Cookies for the purposes set
                out below:
              </p>

              <ul>
                <li>
                  Necessary / Essential Cookies
                  <ul>
                    <li>Type: Session Cookies</li>
                    <li>Administered by: Us</li>
                    <li>
                      Purpose: These Cookies are essential to provide You with
                      services available through the Website and to enable You
                      to use some of its features. They help to authenticate
                      users and prevent fraudulent use of user accounts. Without
                      these Cookies, the services that You have asked for cannot
                      be provided, and We only use these Cookies to provide You
                      with those services.
                    </li>
                  </ul>
                </li>
                <li>
                  Cookies Policy / Notice Acceptance Cookies
                  <ul>
                    <li>Type: Persistent Cookies</li>
                    <li>Administered by: Us</li>
                    <li>
                      Purpose: These Cookies identify if users have accepted the
                      use of cookies on the Website.
                    </li>
                  </ul>
                </li>
                <li>
                  Functionality Cookies
                  <ul>
                    <li>Type: Persistent Cookies</li>
                    <li>Administered by: Us</li>
                    <li>
                      Purpose: These Cookies allow us to remember choices You
                      make when You use the Website, such as remembering your
                      login details or language preference. The purpose of these
                      Cookies is to provide You with a more personal experience
                      and to avoid You having to re-enter your preferences every
                      time You use the Website.
                    </li>
                  </ul>
                </li>
                <li>
                  Tracking and Performance Cookies
                  <ul>
                    <li>Type: Persistent Cookies</li>
                    <li>Administered by: Third-Parties</li>
                    <li>
                      Purpose: These Cookies are used to track information about
                      traffic to the Website and how users use the Website. The
                      information gathered via these Cookies may directly or
                      indirectly identify you as an individual visitor. This is
                      because the information collected is typically linked to a
                      pseudonymous identifier associated with the device you use
                      to access the Website. We may also use these Cookies to
                      test new pages, features or new functionality of the
                      Website to see how our users react to them.
                    </li>
                  </ul>
                </li>
              </ul>

              <p>
                For more information about the cookies we use and your choices
                regarding cookies, please visit our Cookies Policy or the
                Cookies section of our Privacy Policy.
              </p>
            </section>

            <section>
              <h2>Use of Your Personal Data</h2>

              <p>
                The Company may use Personal Data for the following purposes:
              </p>

              <ul>
                <li>
                  To provide and maintain our Service, including to monitor the
                  usage of our Service.
                </li>
                <li>
                  To manage Your Account: to manage Your registration as a user
                  of the Service. The Personal Data You provide can give You
                  access to different functionalities of the Service that are
                  available to You as a registered user.
                </li>
                <li>
                  For the performance of a contract: the development, compliance
                  and undertaking of the purchase contract for the products,
                  items or services You have purchased or of any other contract
                  with Us through the Service.
                </li>
                <li>
                  To contact You: To contact You by email, telephone calls, SMS,
                  or other equivalent forms of electronic communication, such as
                  a mobile application's push notifications regarding updates or
                  informative communications related to the functionalities,
                  products or contracted services, including the security
                  updates, when necessary or reasonable for their
                  implementation.
                </li>
                <li>
                  To provide You with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless You have opted not to
                  receive such information.
                </li>
                <li>
                  To manage Your requests: To attend and manage Your requests to
                  Us.
                </li>
                <li>
                  For business transfers: We may use Your information to
                  evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of Our assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  Personal Data held by Us about our Service users is among the
                  assets transferred.
                </li>
                <li>
                  For other purposes: We may use Your information for other
                  purposes, such as data analysis, identifying usage trends,
                  determining the effectiveness of our promotional campaigns and
                  to evaluate and improve our Service, products, services,
                  marketing and your experience.
                </li>
              </ul>

              <p>
                We may share Your personal information in the following
                situations:
              </p>

              <ul>
                <li>
                  With Service Providers: We may share Your personal information
                  with Service Providers to monitor and analyze the use of our
                  Service, for payment processing, to contact You.
                </li>
                <li>
                  For business transfers: We may share or transfer Your personal
                  information in connection with, or during negotiations of, any
                  merger, sale of Company assets, financing, or acquisition of
                  all or a portion of Our business to another company.
                </li>
                <li>
                  With Affiliates: We may share Your information with Our
                  affiliates, in which case we will require those affiliates to
                  honor this Privacy Policy. Affiliates include Our parent
                  company and any other subsidiaries, joint venture partners or
                  other companies that We control or that are under common
                  control with Us.
                </li>
                <li>
                  With business partners: We may share Your information with Our
                  business partners to offer You certain products, services or
                  promotions.
                </li>
                <li>
                  With other users: when You share personal information or
                  otherwise interact in the public areas with other users, such
                  information may be viewed by all users and may be publicly
                  distributed outside. If You interact with other users or
                  register through a Third-Party Social Media Service, Your
                  contacts on the Third-Party Social Media Service may see Your
                  name, profile, pictures and description of Your activity.
                  Similarly, other users will be able to view descriptions of
                  Your activity, communicate with You and view Your profile.
                </li>
                <li>
                  With Your consent: We may disclose Your personal information
                  for any other purpose with Your consent.
                </li>
              </ul>
            </section>

            <section>
              <h2>Retention of Your Personal Data</h2>

              <p>
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </p>

              <p>
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of Our Service, or We
                are legally obligated to retain this data for longer time
                periods.
              </p>
            </section>

            <section>
              <h2>Transfer of Your Personal Data</h2>
              <p>
                Your information, including Personal Data, is processed at the
                Company's operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country, or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction.
              </p>
              <p>
                Your consent to this Privacy Policy followed by Your submission
                of such information represents Your agreement to that transfer.
              </p>
              <p>
                The Company will take all steps reasonably necessary to ensure
                that Your data is treated securely and in accordance with this
                Privacy Policy, and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place, including the security of Your data and other
                personal information.
              </p>
              <h5>Disclosure of Your Personal Data</h5>
              <p>
                <strong>Business Transactions</strong>
                <br />
                If the Company is involved in a merger, acquisition, or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </p>
              <h5>Law enforcement</h5>
              <p>
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g., a court
                or a government agency).
              </p>
              <h5>Other legal requirements</h5>
              <p>
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
              </p>
              <ul>
                <li>Comply with a legal obligation</li>
                <li>
                  Protect and defend the rights or property of the Company
                </li>
                <li>
                  Prevent or investigate possible wrongdoing in connection with
                  the Service
                </li>
                <li>
                  Protect the personal safety of Users of the Service or the
                  public
                </li>
                <li>Protect against legal liability</li>
              </ul>
            </section>

            <section>
              <h2>Security of Your Personal Data</h2>
              <p>
                The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to
                use commercially acceptable means to protect Your Personal Data,
                We cannot guarantee its absolute security.
              </p>
            </section>

            <section>
              <h2>
                Detailed Information on the Processing of Your Personal Data
              </h2>
              <p>
                The Service Providers We use may have access to Your Personal
                Data. These third-party vendors collect, store, use, process and
                transfer information about Your activity on Our Service in
                accordance with their Privacy Policies
              </p>
            </section>

            <section>
              <h2>Analytics</h2>

              <p>
                We may use third-party Service providers to monitor and analyze
                the use of our Service.
                <h2>Google Analytics</h2>
                <p>
                  Google Analytics is a web analytics service offered by Google
                  that tracks and reports website traffic. Google uses the data
                  collected to track and monitor the use of our Service. This
                  data is shared with other Google services. Google may use the
                  collected data to contextualize and personalize the ads of its
                  own advertising network. You can opt-out of having made your
                  activity on the Service available to Google Analytics by
                  installing the Google Analytics opt-out browser add-on. The
                  add-on prevents the Google Analytics JavaScript (ga.js,
                  analytics.js and dc.js) from sharing information with Google
                  Analytics about visits activity. For more information on the
                  privacy practices of Google, please visit the Google Privacy &
                  Terms web page: https://policies.google.com/privacy
                </p>
                <h2>Email Marketing</h2>
                <p>
                  We may use Your Personal Data to contact You with newsletters,
                  marketing or promotional materials and other information that
                  may be of interest to You. You may opt-out of receiving any,
                  or all, of these communications from Us by following the
                  unsubscribe link or instructions provided in any email We send
                  or by contacting Us. We may use Email Marketing Service
                  Providers to manage and send emails to You.
                  <br></br>
                  We may provide paid products and/or services within the
                  Service. In that case, we may use third-party services for
                  payment processing (e.g. payment processors). We will not
                  store or collect Your payment card details. That information
                  is provided directly to Our third-party payment processors
                  whose use of Your personal information is governed by their
                  Privacy Policy. These payment processors adhere to the
                  standards set by PCI-DSS as managed by the PCI Security
                  Standards Council, which is a joint effort of brands like
                  Visa, Mastercard, American Express and Discover. PCI-DSS
                  requirements help ensure the secure handling of payment
                  information.
                  <br></br>
                </p>
                <h2>Links to Other Websites</h2>
                <p>
                  Our Service may contain links to other websites that are not
                  operated by Us. If You click on a third party link, You will
                  be directed to that third party's site. We strongly advise You
                  to review the Privacy Policy of every site You visit.
                  <br></br>
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites or services.
                  <br></br>
                  Changes to this Privacy Policy
                  <br></br>
                  We may update Our Privacy Policy from time to time. We will
                  notify You of any changes by posting the new Privacy Policy on
                  this page.
                  <br></br>
                  We will let You know via email and/or a prominent notice on
                  Our Service, prior to the change becoming effective and update
                  the "Last updated" date at the top of this Privacy Policy.
                  <br></br>
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page. Transfer of Your Personal Data
                  <br></br>
                  Contact Us If you have any questions about this Privacy
                  Policy, You can contact us: • By email:
                  nimmacinemadvg@gmail.com
                </p>
                <h2>Changes to this Privacy Policy</h2>
                <p>
                  We may update Our Privacy Policy from time to time. We will
                  notify You of any changes by posting the new Privacy Policy on
                  this page.
                  <br />
                  We will let You know via email and/or a prominent notice on
                  Our Service, prior to the change becoming effective and update
                  the "Last updated" date at the top of this Privacy Policy.
                  <br />
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page. Transfer of Your Personal Data
                  <br />
                </p>
                <h2>Contact Us</h2>
                <p>
                  If you have any questions about this Privacy Policy, You can
                  contact us: • By email: nimmacinemadvg@gmail.com
                </p>
              </p>
            </section>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default Footer
