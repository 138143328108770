import React from 'react'
import movieDate from '../assets/images/about1.jpeg'
import { CiCircleCheck } from 'react-icons/ci'
import corporateparty from '../assets/images/corporateparty.webp'
import bridalcelebration from '../assets/images/bridalcelebration.webp'
import bdaycelebration from '../assets/images/about2.jpeg'

const OurServices = () => {
  return (
    <div className="our-services" id="what_you_can_do">
      <div className="heading">What You Can Do</div>

      <div className="our-services1">
        <img src={movieDate} alt="error" />
        <div className="des1">
          <div className="heading">Movie dates</div>
          <div className="data" style={{ marginBottom: '3rem' }}>
            Plan the ideal romantic rendezvous at Binge Club. It's not just a
            date; it's a celebration of your love in the most romantic setting.
          </div>
          <div className="data">
            <CiCircleCheck style={{ marginRight: '1rem' }} />
            Bask in the intimacy of your own private screen with your beloved
          </div>
          <div className="data">
            <CiCircleCheck style={{ marginRight: '1rem' }} />
            Indulge in your favorite films
          </div>
          <div className="data">
            <CiCircleCheck style={{ marginRight: '1rem' }} />
            Immerse yourselves in dreamy decor and relish our exquisite snacks.
          </div>
        </div>
      </div>

      <div className="our-services1 our-services-2">
        <img src={bdaycelebration} alt="error" />
        <div className="des1">
          <div className="heading">Birthdays and anniversaries</div>
          <div className="data" style={{ marginBottom: '3rem' }}>
            Celebrate your birthday or anniversary uniquely at Binge Club, where
            your special day shines in a festive setting.
          </div>
          <div className="data">
            <CiCircleCheck style={{ marginRight: '1rem' }} />
            Enjoy the spacious screen, accommodating up to 12 guests
          </div>
          <div className="data">
            <CiCircleCheck style={{ marginRight: '1rem' }} />
            Dive into your favorite movies
          </div>
          <div className="data">
            <CiCircleCheck style={{ marginRight: '1rem' }} />
            Indulge in delightful cakes amidst charming decor
          </div>
        </div>
      </div>

      <div className="our-services1">
        <img src={corporateparty} alt="error" />
        <div className="des1">
          <div className="heading">Proposal</div>
          <div className="data" style={{ marginBottom: '3rem' }}>
            Dream up the perfect proposal at Binge Club, a moment crafted for
            eternity.
          </div>
          <div className="data">
            <CiCircleCheck style={{ marginRight: '1rem' }} />A private screening
            with dreamy decor
          </div>
          <div className="data">
            <CiCircleCheck style={{ marginRight: '1rem' }} />A customizable
            video of cherished memories
          </div>
          <div className="data">
            <CiCircleCheck style={{ marginRight: '1rem' }} />A surprise that
            captures the heart.
          </div>
        </div>
      </div>

      <div className="our-services1 our-services-2">
        <img src={bridalcelebration} alt="error" />
        <div className="des1">
          <div className="heading">Bridal Shower</div>
          <div className="data" style={{ marginBottom: '3rem' }}>
            Celebrate your birthday or anniversary uniquely at Binge Club, where
            your special day shines in a festive setting.
          </div>
          <div className="data">
            <CiCircleCheck style={{ marginRight: '1rem' }} />
            Enjoy the spacious screen, accommodating up to 12 guests
          </div>
          <div className="data">
            <CiCircleCheck style={{ marginRight: '1rem' }} />
            Dive into your favorite movies
          </div>
          <div className="data">
            <CiCircleCheck style={{ marginRight: '1rem' }} />
            Indulge in delightful cakes amidst charming decor
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurServices
