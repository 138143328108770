import React from 'react'
import about1 from '../assets/images/nimma2.jpeg'

const Aboutus = () => {
  return (
    <div className="about" id="about-us">
      <div className="about1">
        <img src={about1} alt="error" />
        <div className="des1">
          <div className="heading">About Nimma Cinema</div>
          <p>
            Nimma cinema is a stage of the art private home thearter Combining
            Entertainment,Ecucation and Events
          </p>
          <p>
            At Nimma Cinema you can enjoy large screen,Dolby Atmos Sound
            experience of your films,Sports,Documentaries, Wedding Shoots etc.,
            with small groups of people like Family, Friends,Colllegues and
            Corporate ofiice stafs .
          </p>
          <p>
            Make the theater <span>Home</span> ! Experince the fun in Straeming
            your favorite series,movies and sports events etc., in your private
            mini theater.
          </p>
          <p>
            Join us to watch latest blockbuster Bollywood movies, Regional
            movies,Exclusive TV shows as well as awrd- Winning movies on OTT
            platforms and play your favorite games on large scrren .
          </p>
        </div>
      </div>
    </div>
  )
}

export default Aboutus
