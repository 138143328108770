import React from 'react'

const AddOnServices = () => {
  return (
    <div className="addonservices-cont" id="addonservices">
      <div className="contentoverlay">
        <div className="heading">Add on Services</div>
        <div className="services">
          CAKES, BOUQUE, SPECIAL DECORATIONS, CINEMATIC GIFTS, HOME PARTY
          DECORATIONS, ENTERTAINMENT, ACCESSORIES, HOME THEATER SERVICES
        </div>
      </div>
    </div>
  )
}

export default AddOnServices
